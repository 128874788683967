<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Убайдуллахўжа Асaдуллaхўжaев <br />(1878-1937)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            AСAДУЛЛAХЎЖAЙЕВ Убайдуллахўжа (айрим манбаларда Хўжайев Убайдулла)
            (1878, Тошкент — 1937) — жамоат арбоби, жадидчилик ҳаракатининг
            йирик вакили, ўзбек матбуотининг илк ташкилотчиларидан бири.
            Тошкентдаги рус-тузем мактабини битиргач, маҳкамалардан бирида
            тилмоч бўлиб ишлади (1897). Россиянинг Саратов шаҳридаги
            ҳуқуқшунослик ин-тида ўқиди (1908—12). Талабалик йилларидаёқ давр
            муаммоларини ечишни ўйлади, рус ёзувчиси Л. Н. Толстой б-н ёзишмалар
            қилди (1909). Ўқишни тугатгач, дастлаб Саратов, 1913-йилда Тошкент
            округ судида хусусий адвокат бўлиб ишлади. Чор ҳукумати
            маъмурларининг порахўрлиги ва золимлигини фош етиб, айримларини
            давлат ишидан четлатишга еришди. Жадидчилик ҳаракатига қўшилиб, тез
            орада унинг йирик вакилларидан бирига айланди. «Умид» деб аталувчи
            илғор мусулмон гуруҳига йетакчилик қилди (1913). «Турон» жамиятининг
            асосчиларидан бири (1913). У мустақил фирқа ташкил қилиш ниятида
            Тошкентда «Садои Туркистон» газ. ини ташкил етиб (1914.4.4), унга
            муҳаррирлик қилди. У газ. да турли мавзуларда долзарб мақолалар
            билан чиқиб, чоризмнинг мустамлакачилик сиёсатини фош қилди.
            Туркистонга тарқалиб, умуммиллий матбуот нашрига айланаётган «Садои
            Туркистон» 1915-й. апр. да ёпиб қўйилган бўлсада, у ўлка
            зиёлиларининг «Тараққийпарварлар» фирқасига замин тайёрлади. A.
            Тошкентда тузилиб, мардикорликка олиш иши билан шуғулланган
            «Туркистон мардикорликка олиш қўмитаси»нинг раиси (1916). Қўмита чор
            амалдорларининг ўзбошимчаликларини очиб ташлади. Мардикорликка
            жўнатиш ҳақидаги оқ подшо фармони (1916-й. 25 июн)ни бекор қилдириш
            учун Aсадуллахўжайев маҳаллий миллатпарвар бой Миркомил
            Мирмўминбойев билан бирга Петербургга борди. Давлат думасининг
            1916-й. дек. даги мажлисида фармон Россия империясининг
            қонунчилигида кўрсатилган ҳолларга зид равишда қабул қилингани
            исботлаб берилди. Aсадуллахўжайев 1917-й. Туркистондаги сиёсий
            жараёнларда фаол қатнашди. «Шўрои ислом» жамияти раиси. Бутун
            Туркистон мусулмонлари И-сйездида ташкил қилинган Туркистон ўлка
            мусулмонлари марказий кенгаши котиби ва аъзоларидан бири (1917-й.
            апр.). Бутун Россия мусулмонлар кенгаши МК аъзоси (1917).
            Мухториятчилик ҳаракати ташаббускорларидан. Туркистон мухторияти
            ҳукуматининг ҳарбий ишлар вазири (1917-й. нояб. — 1918-й. фев.).
            Ҳукумат топшириғи билан Кавказга борди ва Туркистонга ғалла келтириш
            масаласини ҳал қилди. Истиқлолчилик ҳаракатига хайрихоҳлик билан
            қарагани, мустамлакачиликка қарши курашгани учун «миллатчилик»да
            айбланиб қатағонга учраган. Бир неча марта қамалиб, турмада ҳалок
            бўлди.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          <b-col sm="1">
            <b-button
              @click="$router.push({ name: 'usmonxoja' })"
              style="text-align:right"
              variant="outline-success"
            >
              {{ $t("Next") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
